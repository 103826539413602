<template>
  <v-app>
    <dashboard-core-app-bar
      v-model="expandOnHover"
      :color="cluster === 'test' ? 'warning' : 'transparent'"
      :dark="cluster === 'test'"
    />
    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />
    <dashboard-core-view />
    <notifications
      group="dashboard"
      position="bottom right"
    />
  </v-app>
</template>

<script>
export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar'),
    DashboardCoreDrawer: () => import('./components/core/Drawer'),
    DashboardCoreView: () => import('./components/core/View')
  },

  data: () => ({
    expandOnHover: false,
    cluster: process.env.CLUSTER
  })
}
</script>
